html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  color: #333333;
}

textarea {
  min-height: 50px;
}

.bg-change {
  background: #fafafa;
}

h2 {
  color: #000;
  text-transform: uppercase;
  font-weight: bold !important;
  margin-bottom: 0px !important;
  margin-left: 8px !important;
}

th, td {
  text-align: center;
}

/* // .container-fluid {
//   padding: 35px;
//   padding-top: 10px;
// } */

.action-container {
  display: flex;
  width: 100%;
}

.action-content {
  flex: 1;
}

/* // .text-start {
//   text-align: left !important;
// }

// .action-buttons {
//   padding-left: 5px;
// } */

.full-width {
  width: 100%;
}

/* // .me-4 {
//   margin-right: 1.5rem !important;
// }

// .mt-1 {
//   margin-top: 0.25rem !important;
// } */

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  background-color: #2680AB;
  /* // border-radius: 8px; */
}

/* //  .mat-flat-button.mat-accent,
//  .mat-raised-button.mat-accent,
//  .mat-fab.mat-accent,
//  .mat-mini-fab.mat-accent {
//    background-color: #919496;
//    border-radius: 8px;
//  } */

.mat-card {
  border-radius: 8px !important;
}

div.mat-ripple.mat-tab-label.mat-focus-indicator.mat-tab-label-active {
  background-color: #2680AB !important;
  color: white !important;
  opacity: 1 !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-weight: normal;
}

/* // .mat-drawer-container {
//   background-color: #ffffff;
//   color: #000000de;
// } */

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: #0009;
}

/* // div.mat-tab-body-content.ng-trigger.ng-trigger-translateTab {
//   background-color: whitesmoke;
// }

// div.mat-drawer-inner-container {
//   background-color: #2680AB;
//   border-right-style: solid;
//   border-right-width: 1px;
//   border-right-color: #2680AB;
// } */

.mat-drawer-inner-container {
  overflow: visible !important;
}

.mat-toolbar {
  background: #ffffff;
  color: #5b6166;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
}

mat-ink-bar {
  display: none;
}

.mat-form-field-outline {
  background-color: white;
}

.cabecera {
  text-align: center;
  padding: 10px;
  color: white;
  font-weight: normal;
  background-color: #fafafa;
}

/* // .text-end {
//   text-align: right;
//   padding-bottom: 10px;
// } */

/*--css julio--*/

.titulo-cabecera {
  margin-left: 16px;
  font-size: 18px;
  font-weight: 300;
  color: #000;
}

.titulo-p {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 20px;
}

.subtitulo-p {
  font-size: 18px;
  /* background: #f1f1ee80; */
  padding: 10px 0px;
  font-weight: 500;
  border-radius: 8px;
  margin-bottom: 16px;
  color: #2680AB;
}

.subtitulo-s {
  font-size: 18px;
  font-weight: bold;
  color: #2680AB;
}

.mat-radio-button {
  padding: 10px !important;
}

ul {
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.lista-opciones ul {
  margin-bottom: 0px;
  padding-bottom: 0px;
  list-style: none;
  padding-inline-start: 0px;
}

.lista-opciones ul li {
  float: left;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: #000 !important;
}

.mat-form-field-label {
  color: #000 !important;
}

/* // .mat-form-field-outline:not( .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick){
//   color: black;
// } */

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #2680AB;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #2680AB;
}

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336 !important;
}

.barra-bandeja-titulo {
  background-color: #f7f7f7;
  height: 48px;
  margin-bottom: 16px;
  border-radius: 6px;
  margin-top: -11px;
}

.bg-r {
  background: #2680AB;
}

.ml-3 {
  margin-right: 16px;
}

.mr-3 {
  margin-right: 8px;
}

.c-1 {
  color: #2196f3;
}

.c-2 {
  color: #9c27b0;
}

.c-3 {
  color: #ffc107;
}

.c-4 {
  color: #f44336;
}

.c-5 {
  color: #4caf50;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #2680AB;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #2680AB;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #2680AB
}

.number-card {
  font-weight: bold;
  font-size: 18px;
}

.sort {
  color: #2680AB;
}

.coment-card {
  color: #2680AB;
}

.info-card {
  border: 1px solid #0dcaf0;
  border-radius: 4px;
  padding: 15px;
  background: #ebfcff;
}

.separador {
  border-bottom: 1px solid #e9ecef;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.green {
  width: 64px !important;
  height: 64px !important;
  font-size: 64px;
  color: #4caf50;
}

.yellow {
  width: 64px !important;
  height: 64px !important;
  font-size: 64px;
  color: #ffc107;
}

.red {
  width: 64px !important;
  height: 64px !important;
  font-size: 64px;
  color: #f44336;
}

.mat-stroked-button.mat-primary {
  color: #666;
}

@media (max-width: 599px) {
  .barra-bandeja-titulo {
    height: auto;
    padding: 16px 8px;
  }

  .number-card {
    font-size: 15px;
  }

  .btn-av {
    display: block;
    width: 100%;
    margin-bottom: 16px !important;
  }

  .btn-mob button {
    display: block;
    margin-bottom: 16px;
    width: 100%;
  }

  .number-card {
    font-size: 15px;
  }

  .btn-av {
    display: block;
    width: 100%;
    margin-bottom: 16px !important;
  }

  .subtitulo-s {
    margin-bottom: 16px;
  }

  h1 {
    text-align: center;
  }

  .img-mob {
    text-align: center !important;
  }
}




.icon-flecha {
  font-size: 26px !important;
  color: rgba(0, 0, 0, 0.54) !important;
}

.color-grey {
  color: #999;
}

.mat-checkbox-layout {
  white-space: break-spaces !important;
}

hr {
  background-color: rgb(235, 235, 235);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #2680AB !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgb(255 1 0 / 54%) !important;
}

.validar {
  background: #2680AB;
  color: #fff;
  margin-top: 10px;
  cursor: pointer !important;
}

.validar:hover {
  color: #fff;
  ;
}

.color-line {
  color: #ccc;
  font-weight: 300;
}

p {
  font-size: 16px;
}

.monitor {
  height: 100vh;
}

.mat-progress-bar-background {
  fill: #fafafa;
}

.mat-progress-bar-fill::after {
  background-color: #2680AB;
}
